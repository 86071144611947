<template>
  <div>
    <static-fullscreen-card v-show="!idItemShow">
      <template v-slot:header></template>
      <template v-slot:actions>
        <a-btn-refresh @click="$refs.table.updateData()" />
        <a-btn-add :icon="'far fa-plus-square'" :title="'Создать документ'" v-if="getAccess('documents.goodsReserve.create')" @click="createNew()" />
      </template>
      <portal to="v-main">
        <edit-dialog v-model="showEditDialog" v-if="showEditDialog" :api="url" :m="m" :id="idEdit" @open="onClickRow($event)"></edit-dialog>
      </portal>
      <a-table-f-api
        ref="table"
        :api="url"
        :model="getModelList(m)"
        :useQuery="false"
        :defaults="defaults"
        :selectedRows="selectedRows"
        @click="
          counter += 1;
          onDetectClick($event);
        "
      >
      </a-table-f-api>
      <popup-window v-if="infoSnack" :type="3" :data="infoData" :model="infoModel" />
    </static-fullscreen-card>
    <ViewItem v-if="idItemShow" :idShow="idItemShow" @close="itemShowClose($event)" />
  </div>
</template>

<script>
import { getAccess, genModel, doubleClickDetect, keyDetect } from "@/components/mixings";
export default {
  mixins: [getAccess, genModel, doubleClickDetect, keyDetect],
  components: {
    ViewItem: () => import("./views/goodsReserveView"),
    editDialog: () => import("./dialogs/goodsReserveCreateDialog"),
  },
  data() {
    return {
      idEdit: 0,
      idItemShow: 0,
      showEditDialog: false,
      showSelectDialog: false,
      title: "",
      m: this.$store.getters["config/get"].models.documentGoodsReserve,
      operations: [8, 9, 19],
      operation: null,
      url: "/accounting/doc/goods_reserve",
      defaults: {
        sort: { key: "date_doc", order: "DESC" },
        paramName: "documentGoodsReserve",
      },
      infoData: null,
      infoModel: null,
    };
  },
  created() {
    this.$root.title = "Резервирование товаров";
  },
  computed: {
    permit() {
      return this.getAccess("menu.documentGoodsReserve");
    },
    infoSnack() {
      if (this.keysModified) {
      }
      let res = this.keys?.ctrl;
      this.infoData = null;
      this.infoModel = null;
      if (res) this.showDocumentDetail();
      return res;
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.cashType = null;
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    itemShowClose() {
      this.loading = true;
      this.$refs.table.updateRowData(this.idItemShow);
      this.loading = !true;
      this.idItemShow = 0;
    },
    async onSelectOperation(e) {
      if (!e.value) return;
      let getConfig = await this.onConfigDocIn(e.id);
      if (!getConfig) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Документ не настроен",
        });
        return;
      }
      this.showEditDialogFun(0);
    },
    onSingleClick(d) {},
    onDoubleClick(d) {
      this.onClickRow(d);
    },
    async onClickRow(d) {
      this.idItemShow = d.row.id;
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
    async showDocumentDetail() {
      let id = this.selectedRows[0];
      if (!id) return;
      let data;
      let model;
      const d = await this.$axios.get(this.url + "/" + id);
      if (d.data && d.data.data) {
        let data = d.data.data.data_table;

        model = this.m.listDetail.filter((el) => ["good_name", "amount", "value", "object_id"].includes(el.name));
        this.infoData = data;
        this.infoModel = model;
      }
    },
  },
};
</script>
